import { useEffect, useState } from "react";
import { validarEmail } from "../../utils/validators";
import { useNavigate } from "react-router-dom";

import "./_variants.css"
import "./recovery-pass.css"
import axios from "axios";
import { api } from "../../services";
import Loading from "../../components/loading";
import { toast } from "react-toastify";

const firstColor = "#f17f38"

const RequestPasswordEmailPage = () => {

    const navigate = useNavigate();

    const [recoveryEmail, setRecoveryEmail] = useState("");

    const [errorEmail, setErrorEmail] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (validarEmail(recoveryEmail)) {
            localStorage.setItem("recovery_password", JSON.stringify({
                email: recoveryEmail
            }))
        }
    }, [recoveryEmail]);

    const onNext = (e) => {
        e?.preventDefault();
        if (recoveryEmail) {
            if (validarEmail(recoveryEmail)) {
                requestCoringas();
                return
            }
            setErrorEmail("Por favor digite um email válido.")
        } else {
            setErrorEmail("Por favor digite seu email.")
        }
    }

    const requestCoringas = () => {
        setLoading(true)
        api.get("/senhas/email/validacao", {
            params: {
                email: recoveryEmail,
                opcao: "recuperacao senha"
            }
        })
            .then(({ data }) => {
                if (data) {
                    localStorage.setItem("recovery_password", JSON.stringify({
                        email: recoveryEmail,
                        coringas: data
                    }))
                }
                navigate("/recuperar-senha")
            })
            .catch(() => toast.error("Não foi possível buscar informações do usuário"))
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className="w-100 flex gap-5 flex-col items-center" style={{ height: "100vh", width: "100vw", backgroundColor: "white" }}>
            <div className="recovery-pass-header">
                <a className="container-recovery" href="https://integracaohotline.com.br">
                    <img src="/img/integraHotline.png" alt="Logo" style={{ width: 135 }} />
                </a>
            </div>
            <div className="container-recovery p-4 w-100 flex content-center gap-10 wrap">
                <div style={{ maxWidth: 400 }}>
                    <h1 className="fw-600 fs-28 text-break">Digite seu e-mail <br /> do Integra hotline</h1>
                </div>
                <div className="border-l p-4 w-100 br-2" style={{ maxWidth: 500 }}>
                    <ul className="w-100" style={{ padding: 0 }}>
                        <h5 className="t-em">E‑mail, telefone ou usuário</h5>
                        <form onSubmit={onNext}>
                            <input
                                onChange={(value) => {
                                    setRecoveryEmail(value.target.value)
                                    setErrorEmail("")
                                }}
                                value={recoveryEmail}
                                type="email"
                                className={`p-2 mt-1 ${errorEmail ? 'input-error' : ''} input-mail`} />
                            <p className="fs-12" style={{ color: "red" }}>{errorEmail}</p>
                        </form>
                        <div>
                            <button
                                onClick={onNext}
                                disabled={loading}
                                className={`${loading ? 'p-2' : 'p-1'} mt-1 br-2 text-center pointer btn-email`}
                                style={{ backgroundColor: loading ? '#ababab' : firstColor, color: "white", minWidth: 180 }}>
                                {loading ? <Loading /> : "Continuar"}
                            </button>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default RequestPasswordEmailPage;