import { FaRegUser } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";

import "./_variants.css"
import "./recovery-pass.css"
import { api } from "../../services";
import Loading from "../../components/loading";
import { toast } from "react-toastify";

const firstColor = "#f17f38"
const textColor = "#374151"

const RequestPasswordCode = () => {

    const navigate = useNavigate();

    const [dataRecovery, setDataRecovery] = useState({});

    const [codeValue, setCodeValue] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getInfosRecovery = localStorage.getItem("recovery_password")
        if (!JSON.parse(getInfosRecovery) || !JSON.parse(getInfosRecovery)?.code) {
            navigate("/recuperar-senha/email")
        } else {
            setDataRecovery(JSON.parse(getInfosRecovery))
        }
    }, [])

    const ConfirmCode = (type) => {
        setLoading(true)
        api.post(`/senhas/confirmar/codigo?email=${dataRecovery?.email}&codigo=${codeValue}`)
            .then(({ data }) => {
                if (data?.token) {
                    localStorage.setItem("recovery_password", JSON.stringify({
                        ...dataRecovery,
                        token_uuid: data?.token
                    }))
                    navigate("/recuperar-senha/resetar")
                } else {
                    toast.error("Código expirado ou inválido")
                }
            })
            .catch(() => {
                toast.error("Código expirado ou inválido")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const RenderInfoRequest = () => {
        switch (dataRecovery?.code) {
            case "sms":
                return "celular"
            case "email":
                return "email"
            case "whatsapp":
                return "whatsapp"
            default:
                return ""
        }
    }

    return (
        <div className="w-100 flex gap-5 flex-col items-center" style={{ height: "100vh", width: "100vw", backgroundColor: "white" }}>
            <div className="recovery-pass-header">
                <div className="container-recovery">
                    <img src="/img/integraHotline.png" alt="Logo" style={{ width: 135 }} />
                </div>
            </div>
            <div className="container-recovery p-4 w-100 flex content-center gap-5 wrap">
                <div style={{ maxWidth: 380 }}>
                    <span className="p-0 fs-14 fw-400" style={{ color: textColor }}>INÍCIO DE SESSÃO</span>
                    <h1 className="fw-600 fs-28" style={{ lineHeight: 1.2 }}>Insira o código que enviamos por {dataRecovery?.code?.toUpperCase()}</h1>
                    <p className="mt-1 fs-14 fw-400" style={{ maxWidth: 300, lineHeight: 1.2, color: textColor }}>
                        É um código de 6 dígitos enviado ao {RenderInfoRequest()} {dataRecovery?.coringas ? dataRecovery?.coringas[RenderInfoRequest()] : ""}.
                    </p>
                    <div>
                        <div className="border-l p-2 br-18 flex flex-row mt-2 items-center pointer" onClick={() => navigate("/recuperar-senha/email")}>
                            <button className="btn-icon p-2 br-rounded flex items-center content-center" style={{ backgroundColor: firstColor + 35, width: 40, height: 40 }}>
                                <FaRegUser size={22} color={firstColor} />
                            </button>
                            <div className="p-2">
                                <h4 className="fs-14 fw-300 mb-0" style={{ lineHeight: 1.0 }}>{dataRecovery?.email}</h4>
                                {/* <button className="fs-12 btn p-0 fw-400" style={{ color: firstColor, border: 'none', lineHeight: 1.0 }}>trocar conta</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-l p-4 w-100 br-2 relative" style={{ maxWidth: 500, minHeight: 250, zIndex: 1024 }}>
                    <ul className="w-100 p-0">
                        <h5 className="fs-12 fw-300">Insira o código</h5>
                        <div style={{ zIndex: 1 }}>
                            <VerificationInput
                                value={codeValue}
                                placeholder=""
                                validChars="0-9"
                                autoFocus
                                onChange={e => setCodeValue(e)}
                                inputProps={{
                                    className: "border-0"
                                }}
                                classNames={{
                                    character: "br-4 fs-16 bg-white border c-black",
                                    characterSelected: "focus-primary c-black",
                                    characterFilled: "c-black"
                                }}
                            />
                        </div>
                        <button
                            onClick={ConfirmCode}
                            disabled={loading}
                            className={`p-3 mt-2 btn br-2 text-center ${(codeValue?.length < 6 || loading) ? "c-default" : "pointer"} fs-14 fw-400`}
                            style={{ backgroundColor: (codeValue?.length < 6 || loading) ? "#ababab" : firstColor, color: "white", maxWidth: 200 }}>
                            {loading ? <Loading size={22} /> : "Confirmar código"}
                        </button>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default RequestPasswordCode;