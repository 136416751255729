import axios from "axios";

const api = axios.create({
    baseURL: "https://common.integracaohotline.com.br/"
});

const apiRevenda = axios.create({
    baseURL: "https://revenda.integracaohotline.com.br/"
});

const apiGestor = axios.create({
    baseURL: "https://gestor.integracaohotline.com.br/"
});

const apiAuth = axios.create({
    baseURL: "https://authuser.integracaohotline.com.br/"
});

export {
    api,
    apiRevenda,
    apiGestor,
    apiAuth
}