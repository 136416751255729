import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form"

import Loading from '../../components/loading';
import Input from '../../components/Input';
import { BrazilStates, validarCNPJ, validarCPF } from '../../utils/validators';
import { ExternalService } from '../../services/external';
import { apiGestor, apiRevenda } from '../../services';
import { toast } from 'react-toastify';
import Modal from '../../components/modal';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import ImageSuccess from "../../assets/success.svg";
import AnimationData from "../../assets/nphEWQRCno.json";
import Lottie from 'react-lottie';

const RegisterCeo = () => {

    const firstColor = "#f17f38"

    const [searchParams] = useSearchParams();
    const key = searchParams.get('key');
    const identificador = searchParams.get('identificador');

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        control,
        getValues,
        watch,
        formState: { errors }
    } = useForm();

    const [loading, setLoading] = useState(false);
    const [loadingGetCNPJ, setLoadingGetCNPJ] = useState(false);

    const [showPass, setShowPass] = useState(false);

    const [Successs, setSuccesss] = useState(false);

    useEffect(() => {
        watch("cpf")
    }, [])

    const handleCreate = (data) => {
        setLoading(true)
        apiGestor.post(`ceorede/create?uuid_revenda=${identificador}&key=${key}`, {
            ...data,
            celular: data.celular.replace(/\D/g, ''),
            whatsapp: data?.whatsapp?.replace(/\D/g, '') ?? "",
            uuid_revenda: identificador
        })
            .then(() => {
                setSuccesss(true)
                // toast.success('Administrador registrado com Successso!', 3000, 'success');
                // setTimeout(() => {
                //     window.location.href = "https://integracaohotline.com.br";
                // }, 2000);
            })
            .catch(() => toast.error('Erro ao registrar administrador!', 3000, 'error'))
            .finally(() => {
                setLoading(false);
            })
    }

    if (Successs) {
        return (
            <div className="w-100 flex gap-5 flex-col items-center" style={{
                height: "100vh",
                width: "100vw",
                backgroundColor: "white"
            }}>
                <div className="recovery-pass-header">
                    <div className="container-recovery">
                        <img src="/img/integraHotline.png" alt="Logo" style={{ width: 135 }} />
                    </div>
                </div>
                <div className="container p-4 pl-8 items-center"
                    style={{
                        backgroundColor: "#f3f3f3",
                        borderRadius: 4,
                        paddingLeft: '60px !important',
                        maxWidth: "840px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        paddingTop: 25,
                        paddingBottom: 25,
                        flexDirection: "column"
                    }}>
                    <Lottie
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: AnimationData,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        height={350}
                        width={350}
                    />
                    {/* <img src={ImageSuccess} style={{ maxWidth: 300 }} alt='Success' /> */}
                    <h5 className="fw-bold fs-20 mt-2">
                        Cadastro realizado com sucesso!
                    </h5>
                </div>
            </div>
        )
    }

    return (
        <div className="w-100 flex gap-5 flex-col items-center" style={{ height: "100vh", width: "100vw", backgroundColor: "white" }}>
            <div className="recovery-pass-header">
                <div className="container-recovery">
                    <img src="/img/integraHotline.png" alt="Logo" style={{ width: 135 }} />
                </div>
            </div>
            <div className="container p-4 pl-8" style={{ backgroundColor: "#f3f3f3", borderRadius: 4, paddingLeft: '60px !important', maxWidth: "840px" }}>
                <h5 className="fw-400 fs-20">
                    Cadastro Administrador no App Gestor
                </h5>
                <div className='flex gap-2 flex-col mt-1 overflow-auto'>
                    <div className='row fullwidth gap-4'>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="name">
                                CPF
                            </label>
                            <Controller
                                {...register('cpf', {
                                    required: {
                                        value: true,
                                        message: 'CPF requerido!'
                                    },
                                    validate: (value) => {
                                        const _v = value?.replace(/\D/g, '') ?? "";
                                        if (!validarCPF(_v)) {
                                            return 'CPF inválido.';
                                        }
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        mask={'___.___.___-__'}
                                        replacement={{ _: /\d/ }}
                                        type='text'
                                        placeholder='Digite o CPF'
                                    />
                                )}
                            />
                            {errors?.cpf?.message ? <p className='text-danger'>{errors.cpf.message}</p> : <></>}
                        </div>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Nome
                            </label>
                            <Controller
                                {...register('nome', {
                                    required: {
                                        value: true,
                                        message: 'Nome requerido.'
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`p-2 input-mail fullwidth`}
                                        type='text'
                                        placeholder='Nome'
                                    />
                                )}
                            />
                            {errors?.nome && <p className='text-danger'>{errors.nome.message}</p>}
                        </div>
                    </div>

                    <div className='row fullwidth gap-4'>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Email
                            </label>
                            <Controller
                                {...register('email', {
                                    required: {
                                        value: true,
                                        message: 'Email requerido!'
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`p-2 input-mail fullwidth`}
                                        type='email'
                                        placeholder='exemplo@email.com'
                                    />
                                )}
                            />
                            {errors?.email && <p className='text-danger'>{errors.email.message}</p>}

                        </div>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Celular
                            </label>
                            <Controller
                                {...register("celular", {
                                    required: {
                                        value: true,
                                        message: "Celular requerido!"
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        mask={'(__) _ ____-____'}
                                        replacement={{ _: /\d/ }}
                                        type="text"
                                        placeholder="(00) 9 9999-0000"
                                    />
                                )}
                            />
                            {errors?.celular && <p className='text-danger'>{errors.celular.message}</p>}
                        </div>
                    </div>
                    <div className='row fullwidth gap-4'>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Whatsapp
                            </label>
                            <Controller
                                {...register("whatsapp")}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        mask={'(__) _ ____-____'}
                                        replacement={{ _: /\d/ }}
                                        type="text"
                                        placeholder="(00) 9 9999-0000"
                                    />
                                )}
                            />
                            {errors?.whatsapp && <p className='text-danger'>{errors.whatsapp.message}</p>}
                        </div>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                Cidade
                            </label>
                            <Controller
                                {...register('cidade', {
                                    required: {
                                        value: true,
                                        message: 'Cidade requerida!'
                                    },
                                })}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`p-2 input-mail fullwidth`}
                                        placeholder='Cidade'
                                    />
                                )}
                            />
                            {errors?.cidade && <p className='text-danger'>{errors.cidade.message}</p>}
                        </div>
                    </div>
                    <div className='row fullwidth gap-4'>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="username">
                                UF
                            </label>
                            <Controller
                                {...register('uf_nome', {
                                    required: {
                                        value: true,
                                        message: 'UF requerida!'
                                    },
                                })}
                                control={control}
                                render={({ field }) => (
                                    // <input
                                    //     {...field}
                                    //     className={`p-2 input-mail fullwidth`}
                                    //     placeholder='UF'
                                    // />
                                    <select
                                        {...field}
                                        className='input-mail fullwidth'>
                                        <option value={null}>Selecione</option>
                                        {BrazilStates.map((s) => (
                                            <option value={s}>{s}</option>
                                        ))}
                                    </select>
                                )}
                            />
                            {errors?.uf_nome && <p className='text-danger'>{errors?.uf_nome.message}</p>}
                        </div>
                        <div className="col-md-6">
                            <label className="fw-400 fs-13" htmlFor="name">
                                Senha
                            </label>
                            <Controller
                                {...register('senha', {
                                    required: {
                                        value: true,
                                        message: "Senha requerido."
                                    },
                                    minLength: {
                                        value: 6,
                                        message: "é necessário 6 digitos."
                                    },
                                    maxLength: {
                                        value: 6,
                                        message: "é necessário 6 digitos."
                                    },
                                    valueAsNumber: {
                                        value: true,
                                        message: "Apenas numeros permitidos."
                                    },
                                    validate: (value) => {
                                        if (!/^\d+$/.test(value)) {
                                            return "Apenas números são permitidos.";
                                        }
                                    }
                                })}
                                control={control}
                                render={({ field }) => (
                                    <div className={`input-group`}>
                                        <input
                                            {...field}
                                            type={showPass ? 'number' : 'password'}
                                            className='p-2 input-mail'
                                            placeholder='******'
                                        />
                                        <span
                                            onClick={() => setShowPass(!showPass)}
                                            style={{
                                                border: "0.0625rem solid #dbd6d1",
                                                width: 30,
                                                borderTopRightRadius: '.5rem',
                                                borderBottomRightRadius: '.5rem',
                                                backgroundColor: "white"
                                            }}
                                            className="input-group-text-end-icon flex items-center justify-center pointer">
                                            {showPass ? <FiEyeOff /> : <FiEye />}
                                        </span>
                                    </div>
                                )}
                            />
                            {errors?.senha?.message ? <p className='text-danger'>{errors.senha.message}</p> : <></>}
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: 25, justifyContent: 'flex-end' }}>
                    <button
                        onClick={handleSubmit(handleCreate)}
                        className={`p-2 mt-2 btn br-2 text-center ${loading ? "c-default" : "pointer"} fs-13 fw-400`}
                        style={{ backgroundColor: loading ? "#ababab" : firstColor, color: "white", maxWidth: 200 }}>
                        {loading ? <Loading /> : "Cadastrar"}
                    </button>
                </div>
            </div>
        </div >
    )
}

export default RegisterCeo;